type PropsType = {
  styles?: object;
  stroke?: string;
  fill?: string;
};

const AreaIcon = ({ styles, stroke, fill }: PropsType) => {
  return (
    <svg
      width="18"
      height="17"
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      style={styles}
    >
      <path
        d="M3.2555 2.8722C3.11743 2.8722 3.0055 2.98413 3.0055 3.1222L3.0055 5.3722C3.0055 5.51027 3.11743 5.6222 3.2555 5.6222C3.39357 5.6222 3.5055 5.51027 3.5055 5.3722L3.5055 3.3722L5.5055 3.3722C5.64357 3.3722 5.7555 3.26027 5.7555 3.1222C5.7555 2.98413 5.64357 2.8722 5.5055 2.8722L3.2555 2.8722ZM13.4195 12.9326L3.43228 2.94543L3.07873 3.29898L13.0659 13.2862L13.4195 12.9326Z"
        fill={fill ?? '#303030'}
      />
      <path
        d="M13.243 13.3592C13.3811 13.3592 13.493 13.2473 13.493 13.1092L13.493 10.8592C13.493 10.7212 13.3811 10.6092 13.243 10.6092C13.105 10.6092 12.993 10.7212 12.993 10.8592L12.993 12.8592L10.993 12.8592C10.855 12.8592 10.743 12.9712 10.743 13.1092C10.743 13.2473 10.855 13.3592 10.993 13.3592L13.243 13.3592ZM3.07908 3.29885L13.0663 13.286L13.4198 12.9325L3.43264 2.94529L3.07908 3.29885Z"
        fill={fill ?? '#313131'}
      />
      <rect
        x="0.682617"
        y="0.548828"
        width="15.9014"
        height="15.9014"
        rx="2.5"
        stroke={fill ?? '#313131'}
      />
    </svg>
  );
};
export default AreaIcon;
