import { CTATypes } from '../new-lib/constants/common';
import { phoneLeadsInfo } from '../new-lib/apis';
import sbjs from 'sourcebuster';
import { getTrackerClientId } from '../new-lib/analytics';

export const phoneHelper = async (
  entity: any,
  path: string,
  phone_number: number,
  entityType?: CTATypes
) => {
  const location = setLocation(entity, entityType);

  getTrackerClientId().then(clientId => {
    phoneLeadsInfo({
      client_id: clientId,
      sbjs: sbjs.get,
      url: path,
      telephone: phone_number,
      location: location
    });
  });
};

const setLocation = (entity: any, entityType?: CTATypes): string => {
  switch (entityType) {
    case CTATypes.PROPERTY:
      return entity.compound.name;
    case CTATypes.COMPOUND:
      return entity.name;
    default:
      return entity || '';
  }
};
