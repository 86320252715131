import { getWhatsappNumber } from '../common';
import { CTATypes } from '../../new-lib/constants/common';
import { getTrackerClientId } from '../../new-lib/analytics';
import sbjs from 'sourcebuster';
import { clickWhatsapp } from '../../new-lib/apis';

class WhatsappBuilder {
  public entity?: any;
  public entityType?: string;
  public path: string;
  public referer: string;
  public userId: string | null;
  public eventId: string | null;
  public location?: string;
  public name?: string;
  public company?: string;
  public leadType?: string;
  public WHATSAPP_NUMBER: string;
  public NAWY_URL: string;
  public userData: string;
  public randomEventId: string | null;
  public language: string;

  constructor(
    entity: object,
    path: string,
    referer: string,
    userId_: string,
    eventId_: string,
    entityType?: CTATypes,
    language?: string,
    whatsappNumber?: string
  ) {
    this.entity = entity;
    this.entityType = entityType;
    this.path = path;
    this.referer = referer;
    this.userId = userId_;
    this.eventId = eventId_;
    this.randomEventId = null;
    this.location = '';
    this.name = '';
    this.company = '';
    this.leadType = '';
    this.WHATSAPP_NUMBER = whatsappNumber || getWhatsappNumber(referer);
    this.NAWY_URL = '|||';
    this.userData = '';
    this.language = language ?? 'en'; // default language is "en", then will be set in setName()
    this.WHATSAPP_NUMBER = this.assignWhatsappNumber();
  }

  setLanguage = () => {
    //if this.name includes arabic letters
    if (this.name?.match(/[\u0600-\u06FF]/)) {
      this.language = 'ar';
    } else {
      this.language = 'en';
    }
  };

  setLocation = () => {
    switch (this.entityType) {
      case CTATypes.PROPERTY:
        this.location = this.entity.compound.name;
        break;
      case CTATypes.COMPOUND:
        this.location = this.entity.name;
        break;
      case CTATypes.CITYSCAPE:
        this.location = `${this.entity.compound.name} Cityscape`;
        break;
      case CTATypes.CITYSCAPE_COMPOUND:
        this.location = `${this.entity.name} Cityscape`;
        break;
      case CTATypes.LANDING_PAGE:
        this.location = `${
          this.entity?.compound?.name || this.entity.title || this.entity
        }`;
        break;
      case CTATypes.MOENGAGE:
        this.location = 'moengage';
      case CTATypes.NAWY_NOW:
        this.location = `nawy-now`;
      case CTATypes.OFFERS:
        this.location = 'offers';
        break;
      default:
    }
  };

  setName = () => {
    switch (this.entityType) {
      case CTATypes.PROPERTY:
        this.name = `${this.entity.name}`;
        break;
      case CTATypes.COMPOUND:
        this.name = `${this.entity.name}, ${this.entity.area.name}`;
        break;
      case 'super_compound':
        this.name = `${this.entity.name}, ${this.entity.area.name}`;
        break;
      case CTATypes.CITYSCAPE:
        this.name = this.entity.compound.name;
        break;
      case CTATypes.CITYSCAPE_COMPOUND:
        this.name = this.entity.name;
        break;
      case CTATypes.LANDING_PAGE:
        this.name =
          this.entity?.compound?.name || this.entity.title || this.entity;
        break;
      case CTATypes.LAUNCH:
        if (this.entity && Object.keys(this.entity).length > 0) {
          this.name = `${
            this.language == 'ar' ? 'الاطلاق الجديد' : 'the new launch'
          } ${this.entity.title} `;
        } else {
          this.name =
            this.language == 'ar' ? 'الاطلاقات الجديدة' : 'new launches';
        }
        break;
      case CTATypes.MOENGAGE:
        this.name =
          this.language == 'ar'
            ? 'عرض 10% خصم من تطوير مصر'
            : 'Tatweer Misr 10% discount offer';
        break;
      case CTATypes.SAHEL:
        this.name = `${this.language == 'ar' ? 'خريطة الساحل' : 'Sahel Map'}`;
        break;
      case CTATypes.OFFERS:
        this.name = `${this.language == 'ar' ? 'عروض ناوى' : "Nawy's Offers"}`;
        break;
      case CTATypes.NAWY_NOW:
        this.name = `${
          this.language == 'ar' ? 'وحدات ناوي ناو' : 'Nawy Now Properties'
        }`;
        break;
      default:
    }
    this.setLanguage();
  };

  setLeadType = () => {
    if (!this.entity) return;
    switch (this.entity) {
      case this.entity.resale && this.entity.financing:
        this.leadType = '4';
        break;
      case this.entity.resale:
        this.leadType = '3';
        break;
      default:
        this.leadType = '1';
    }
  };

  setCompany = () => {
    if (!this.entity) return;
    if (this.entity.company && this.entity.name) {
      this.company = 'cb';
    }
  };

  setPath = () => {
    if (this.userId && this.eventId) {
      this.userData = `# ${this.eventId}.`;
    } else {
      this.userData = `# ${this.randomEventId}.`;
    }
  };

  assignWhatsappNumber = () => {
    if (
      this.entity?.nawy_organization_id === 2 ||
      this.entity?.compound?.nawy_organization_id === 2
    ) {
      return '01017248451';
    }
    if (this.entityType === CTATypes.LANDING_PAGE) {
      return '01033339392';
    }
    return getWhatsappNumber(this.referer);
  };

  initializeMessageComponents = (): void => {
    this.userId = localStorage.getItem('user_id');
    this.eventId = localStorage.getItem('event_id');
    this.randomEventId = localStorage.getItem('random_event_id');
    this.setLocation();
    this.setName();
    this.setLeadType();
    this.setCompany();
    this.setPath();
  };

  buildMessage = (): void => {
    let message = '';
    if (this.language === 'ar') {
      message = `أرغب في المزيد من المعلومات ${
        this.name ? `عن ${this.name}` : ''
      }%0A`;
      message += `رقم مرجعى ${this.userData} %0A%0A`;
      message += `*برجاء عدم حذف الرقم المرجعي* ليتمكن  أحد مستشاري العقارات لدينا بمساعدك.`;
    } else {
      message = `I would like more information ${
        this.name ? `on ${this.name}` : ''
      }%0A`;
      message += `Reference ${this.userData} %0A%0A`;
      message += `*Please do NOT delete this message* as our property consultants use this reference number to better assist you.`;
    }

    const messageEncoded = message.replace(/&/g, '%26').replace(/#/g, '%23');
    window.open(
      `https://wa.me/2${this.WHATSAPP_NUMBER}?text=${messageEncoded}
      `,
      '_blank'
    );
  };

  launchWhatsApp = (): void => {
    this.initializeMessageComponents();

    getTrackerClientId()
      .then(clientId => {
        this.buildMessage();
        let requestBody: {
          client_id: any;
          url: string;
          sbjs: any;
          randomEventId?: string;
          compound_id?: string;
          property_id?: string;
        } = {
          client_id: clientId,
          ...((this.entityType === 'property' ||
            this.entityType === 'compound') && {
            [this.entityType + '_id']: this.entity.id
          }),
          url: this.path,
          sbjs
        };
        if (this.randomEventId && !this.eventId) {
          requestBody.randomEventId = this.randomEventId;
        }

        if (
          this.entityType === 'compound' ||
          this.entityType === 'super_compound'
        ) {
          requestBody.compound_id = this.entity.id;
        } else if (this.entityType === 'property') {
          requestBody.property_id = this.entity.id;
        }
        clickWhatsapp(requestBody, 'en');
      })
      .catch(e => {
        window.open(
          `https://wa.me/2${this.WHATSAPP_NUMBER}?text=I would like more information on ${this.name}`,
          '_blank'
        );
        reportError({ source: 'Whatsapp', error: e.stack });
      });
  };
}

export default WhatsappBuilder;
